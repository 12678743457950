import React, {Component} from 'react'
import Aux from '../../hoc/Aux'
import styles from './Layout.module.css'
import NavigationBar from '../Navigation/NavigationBar/NavigationBar'
import Toolbar from '../Navigation/Toolbar/Toolbar'
import Footer from '../Footer/Footer'
import Sidedrawer from '../Navigation/SideDrawer/SideDrawer'


class Layout extends Component{
    state = {
        isSidedrawerActive: false
    }

    componentDidMount(){
        this.detectListener = window.addEventListener("resize", this.detectOrientation)
    }

    detectOrientation = () => {
        if (!!navigator.maxTouchPoints){ //checks we are in mobile mode
            if(window.innerHeight < window.innerWidth){ //checks we are in landscape
                this.setState({isSidedrawerActive: false})
            } 
        }
    }

    changeSideDrawerStateHandler = () =>{
        this.setState(prevState => {
            return {isSidedrawerActive: !prevState.isSidedrawerActive}
        })
    }

    render(){
        return(
            <div className = {styles.Layout}>
                <div className = {styles.Nav}>
                    <Sidedrawer isActive = {this.state.isSidedrawerActive} closeSideDrawerHandler= {this.changeSideDrawerStateHandler}/>
                    <NavigationBar isSidedrawerActive = {this.state.isSidedrawerActive} sidedrawerToggled = {this.changeSideDrawerStateHandler}/>
                    <Toolbar/>
                </div>
                <main className = {styles.Content}>
                    {this.props.children}
                </main>
                <div className = {styles.Footer}>
                    <Footer />
                </div>
                
            </div>
        );
    }
    
}

export default Layout