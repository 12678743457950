import React from 'react' 
import BackDrop from '../../UI/Backdrop/Backdrop'
import styles from './SideDrawer.module.css'
import Aux from '../../../hoc/Aux'
import ToolbarItems from '../Toolbar/ToolbarItems/ToolbarItems'
import NavigationItems from '../NavigationBar/NavigationItems/NavigationItems'

const sidedrawer = (props) => {

    let classes = [styles.Sidedrawer, styles.Close]

    if (props.isActive){
        classes = [styles.Sidedrawer, styles.Open]
    }

    return (
        <Aux>
            <BackDrop coverNavBar = {false} show = {props.isActive} clicked = {props.closeSideDrawerHandler}/>
            <div className = {classes.join(' ')}>
                <ToolbarItems linkClicked = {props.closeSideDrawerHandler} />
                <NavigationItems linkClicked = {props.closeSideDrawerHandler}/>
            </div>
        </Aux>
    );

}

export default sidedrawer