import React from 'react'
import styles from './Modal.module.css'
import Backdrop from '../Backdrop/Backdrop'
import Aux from '../../../hoc/Aux'

const modal = (props) => {
    return(
        <Aux>
            <Backdrop  show = {props.show} clicked = {props.modalClosed}/>
            <div className = {styles.Modal}
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? '1' : '0'
            }}>
                <h2>{props.children}</h2>
            </div>
        </Aux>
    )
}

export default modal