import React from 'react'
import {Link} from 'react-router-dom'
import styles from './NavigationItems.module.css'

const navigationItems = (props) => ( 
    <ul className = {styles.NavigationItems}>
       
        <li className = {styles.Login}><Link to = "/screening" > Find a Screening</Link></li>
    </ul>
)

export default navigationItems