import React from 'react'
import styles from './NavigationBar.module.css'
import Logo from './AppNavTitle/Logo/Logo'
import NavigationItems from './NavigationItems/NavigationItems'
import ToggleButton from '../SideDrawer/ToggleButton/ToggleButton'
import AppNavTitle from './AppNavTitle/AppNavTitle'

const navigationBar = (props) => {

    return (
        <header className = {styles.NavigationBar}>
            <AppNavTitle/>
            <div className = {styles.NavigationItems}>
                <NavigationItems />
            </div>
            <ToggleButton className = {styles.ToggleButton} style = {{display: 'none'}} isActive = {props.isSidedrawerActive} clicked = {props.sidedrawerToggled}/>
        </header>
    );
}

export default navigationBar