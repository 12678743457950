import react from 'react'
import styles from './ErrorBox.module.css'

const ErrorBox = (props) => {
    return(
        <div className = {styles.ErrorBox}>
            <p>{props.message}</p>
        </div>
    )
}

export default ErrorBox