import react from 'react'
import styles from './ToolbarItems.module.css'
import ToolbarItem from './ToolbarItem/ToolbarItem'

const toolbarItems = (props) => (
    <ul className = {styles.ToolbarItems}>
            <ToolbarItem clicked = {props.linkClicked} path = "/">Prostate Cancer</ToolbarItem>
            <ToolbarItem clicked = {props.linkClicked} path = "/clinicaltrials">Clinical Trials</ToolbarItem>
            <ToolbarItem clicked = {props.linkClicked} path = "/support-groups">Support Groups</ToolbarItem>
            <ToolbarItem clicked = {props.linkClicked} path = "/about">About Us</ToolbarItem>
            <ToolbarItem clicked = {props.linkClicked} path = "/contact">Contact Us</ToolbarItem>
        </ul>
)

export default toolbarItems