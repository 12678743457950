import React from 'react'
import styles from './Trial.module.css'
import Image from './Image/Image'
import ViewDetails from './ViewDetails/ViewDetails'
import Aux from '../../../../hoc/Aux'

const trial = (props) => {
    const trial = props.trial
    console.log(trial)
    return(
        <div className = {styles.TrialContainer}>
            <div className = {styles.Trial}>
                    <div className = {styles.Gap}></div>
                    <Image/>
                    <h2 className = {styles.Title}>{trial.BriefTitle}</h2>
                    <p className = {styles.Summary}>{trial.BriefSummary}</p>
                    <div className = {styles.Treatments}>
                        <label>Treatments: </label>
                        <p>onvarstetib, abiraterone onvarstetib, abirateroneonvarstetib, abiraterone</p>
                    </div>
                    <div className = {styles.Phase}>
                        <label>Accepts Healthy Volunteers: </label>
                        <p>{trial.HealthyVolunteers === "No" ? "No" : "Yes"}</p>
                    </div>
                    <div className = {styles.Organization}>
                        <label>Organization: </label>
                        <p>{trial.OrgFullName}</p>
                    </div>
                    <div className = {styles.MinimumAge}>
                        <label>Minimum Age: </label>
                        <p>{trial.MinimumAge}</p>
                    </div>
                    <div className = {styles.Location}>
                        <label>Location: </label>
                        <p>{trial.LocationState}</p>
                    </div>
                    <div className = {styles.MaximumAge}>
                        <label>Maximum Age: </label>
                        <p>{trial.MaximumAge}</p>
                    </div> 
            </div>
            <ViewDetails studyURL = {trial.url}/>
        </div>


        
    );
}

export default trial