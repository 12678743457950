import updateObject from '../utility'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
    token: localStorage.getItem('token'),
    loading: null,
    userId: null,
    email: null,
    error: null
}

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        email: action.email,
        error: null,
        loading: null
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        token: null,
        email: null,
        userId: null,
        loading: false,
        error: action.error
    })
}
const authLogout = (state, action) => {
    localStorage.clear()
    return updateObject(state, {
        token: null,
        userId: null,
        email: null
    })
}

const authReducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_START: return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action)
        case actionTypes.AUTH_LOGOUT: return authLogout(state,action)
        default: return(state)
    }
}

export default authReducer