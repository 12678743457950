import React from 'react'
import styles from './Boxes.module.css'
import Box from './Box/Box'
import image1 from '../../../Assets/ProstateCancerImages/ProstateCancer1.png'
import image2 from '../../../Assets/ProstateCancerImages/ProstateCancer2.png'
import image3 from '../../../Assets/ProstateCancerImages/ProstateCancer3.png'
import image4 from '../../../Assets/ProstateCancerImages/ProstateCancer4.png'
import image5 from '../../../Assets/ProstateCancerImages/ProstateCancer5.png'
import image6 from '../../../Assets/ProstateCancerImages/ProstateCancer6.png'

const Boxes = (props) => (
    <div className = {styles.Boxes}>
        <Box link = 'https://www.mayoclinic.org/diseases-conditions/prostate-cancer/symptoms-causes/syc-20353087' img = {image1} title = "Prostate Cancer Symptoms And Signs"/>
        <Box link =  'https://www.cdc.gov/cancer/prostate/basic_info/screening.htm' img = {image2} title = "Prostate Cancer Screening"/>
        <Box link =  'https://www.pcf.org/about-prostate-cancer/what-is-prostate-cancer/how-it-grows/' img = {image3} title = "How Prostate Cancer grows"/>
        <Box link =  'https://www.mayoclinic.org/diseases-conditions/prostate-cancer/symptoms-causes/syc-20353087' img = {image4} title = "What Causes Prostate Cancer"/>
        <Box link =  'https://www.cancer.org/cancer/prostate-cancer/detection-diagnosis-staging/survival-rates.html' img = {image5} title = "Prostate Cancer Survival Rate"/>
        <Box link =  'https://www.cancer.org/latest-news/how-family-history-affects-prostate-cancer-risk.html' img = {image6} title = "Prostate Cancer and Family"/>
    </div>
)

export default Boxes