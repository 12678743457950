import React from 'react'
import styles from './ToolbarItem.module.css'
import {NavLink} from 'react-router-dom'


const toolbarItem = (props) => {


    return (
        <li onClick = {props.clicked} className = {styles.ToolbarItem}>
            <NavLink  activeClassName = {styles.Active} to = {props.path} exact>{props.children}</NavLink>
        </li>
    )
}

export default toolbarItem