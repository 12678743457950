import { render } from '@testing-library/react'
import React, {Component} from 'react'
import SearchContainer from '../../Components/SearchContainer/SearchContainer'
import Trials from '../../Components/ClinicalTrialsPage/Trials/Trials'
import styles from './CliniclaTrialsPage.module.css'
import Aux from '../../hoc/Aux'
import axios from '../../axios'

import WithError from '../../hoc/withErrorHandler'
import Spinner from '../../Components/UI/Spinner/Spinner'
import LandingPage from '../../Components/ClinicalTrialsPage/LandingPage/LandingPage'


class ClinicalTrialsPage extends Component{

    state = {
        searchContainer : {
            badgeTitle: 'Clinical Trials',
            subtitle: 'Find a Clinical Trial',
            inputs: [
               
                {
                    key: 'location',
                    placeholder: 'Enter your City',
                    type: null
                },{
                    key: 'age',
                    placeholder: 'Enter your Age',
                    type: 'number'
                }, 
                {
                    key: 'healthStatus',
                    type: "healthStatus"
                },
            ]
        },

        searchInput: [
            {
                key: 'healthStatus',
                value: null,
            },
            {
                key: 'location',
                value: null,
            },
            {
                key: 'age',
                value: null
            }
        ],
        trials: [],
        loadingTrials: null,
        error: false,
        noTrialsFound: false,
        showLandingPage: true
        
    }
    componentDidMount(){
        
    }

    submitHandler = (event) => {
        if(this.state.showLandingPage){
            this.setState({showLandingPage: false})
        }
        event.preventDefault()
        this.setState({loadingTrials: true})
        const params = {isHealthy: this.state.searchInput[0].value,
                        location: this.state.searchInput[1].value,
                        age: this.state.searchInput[2].value
                    }
                
        let trials = []
        axios.get('/study/get', {params} ).then(response => {
            const data = response.data.data

            this.state.noTrialsFound = false
            if(data.length === 0){
                this.state.noTrialsFound = true
            }
            data.map(trial => {
                let newTrial = {}
                for(const [key, value] of Object.entries(trial)){
                    newTrial[key] = Array.isArray(value) ? value[0] : value
                } 
                console.log(newTrial)
                trials.push(newTrial)
            })
            let updatedTrials = {...this.state.trials}
            updatedTrials = trials
            this.setState({trials: updatedTrials, loadingTrials: false})
        }).catch(error => {
            this.setState({error: true, loadingTrials: false, trials: []})
        })
    }

    locationChangeHandler = (newLocation) => {

        const acronymToFullName = (acronym) => {
            const json = {
              AZ: 'Arizona',
              AL: 'Alabama',
              AK: 'Alaska',
              AR: 'Arkansas',
              CA: 'California',
              CO: 'Colorado',
              CT: 'Connecticut',
              DC: 'District of Columbia',
              DE: 'Delaware',
              FL: 'Florida',
              GA: 'Georgia',
              HI: 'Hawaii',
              ID: 'Idaho',
              IL: 'Illinois',
              IN: 'Indiana',
              IA: 'Iowa',
              KS: 'Kansas',
              KY: 'Kentucky',
              LA: 'Louisiana',
              ME: 'Maine',
              MD: 'Maryland',
              MA: 'Massachusetts',
              MI: 'Michigan',
              MN: 'Minnesota',
              MS: 'Mississippi',
              MO: 'Missouri',
              MT: 'Montana',
              NE: 'Nebraska',
              NV: 'Nevada',
              NH: 'New Hampshire',
              NJ: 'New Jersey',
              NM: 'New Mexico',
              NY: 'New York',
              NC: 'North Carolina',
              ND: 'North Dakota',
              OH: 'Ohio',
              OK: 'Oklahoma',
              OR: 'Oregon',
              PA: 'Pennsylvania',
              RI: 'Rhode Island',
              SC: 'South Carolina',
              SD: 'South Dakota',
              TN: 'Tennessee',
              TX: 'Texas',
              UT: 'Utah',
              VT: 'Vermont',
              VA: 'Virginia',
              WA: 'Washington',
              WV: 'West Virginia',
              WI: 'Wisconsin',
              WY: 'Wyoming',
              AS: "American Samoa",
              GU: "Guam",
              MP: "Northern Mariana Islands",
              PR: "Puerto Rico",
              VI: "U.S. Virgin Islands",
              UM: "U.S. Minor Outlying Islands",
            }
            if (json[acronym] != null) {
              return json[acronym];
            }
            return acronym;
          }

        var updatedState = {...this.state}

        let selectedState = newLocation.description.split(",")[0] !== "New York" ? newLocation.description.split(",")[1] : newLocation.description.split(",")[0]
        console.log(selectedState)
        if(selectedState.trim().length < 3){
            selectedState = acronymToFullName(newLocation.description.split(",")[1].trim())
        }
        console.log(selectedState)
        const indexOfInputToUpdate = updatedState.searchInput.findIndex(input => {
            return input.key === "location"
        })

        updatedState.searchInput[indexOfInputToUpdate].value = selectedState

        this.setState({
            ...this.updatedState
        })

    }

    inputChangeHandler = (event) =>{

        var updatedState = {...this.state}
        
        let inputToUpdate = updatedState.searchInput.find(input => { 
           return input.key === event.target.name
        })

        const indexOfInputToUpdate = updatedState.searchInput.findIndex(input => {
            return input.key === inputToUpdate.key
        })


        inputToUpdate.value = event.target.value
        updatedState.searchInput[indexOfInputToUpdate] = inputToUpdate
        
        this.setState({
            ...this.updatedState
        })
    }

    render(){
        
        let trials 

        if(this.state.noTrialsFound){
            trials = <h3>No Clinical Trials found</h3>
        }else{
            trials = <Trials trials = {this.state.trials} loading = {this.state.loadingTrials}/>
        }

        if(this.state.loadingTrials){
            trials = <Spinner/>
        }


        return (
            <Aux>
                <div className = {styles.ClinicalTrialsPage}>
                    {this.state.showLandingPage ?
                        <LandingPage submitHandler = {this.submitHandler} 
                            inputChangeHandler = {this.inputChangeHandler} 
                            locationChangeHandler = {this.locationChangeHandler} 
                            loading = {this.state.loadingTrials} 
                            setup = {this.state.searchContainer}/>
                        :
                        <div>
                            <SearchContainer 
                                submitHandler = {this.submitHandler} 
                                inputChangeHandler = {this.inputChangeHandler} 
                                locationChangeHandler = {this.locationChangeHandler} 
                                loading = {this.state.loadingTrials} 
                                setup = {this.state.searchContainer}/>
                            {trials}
                        </div> 
                    }
                </div>
            </Aux>
            
        )
    }
}

export default WithError(ClinicalTrialsPage, axios)