import React from 'react'
import Logo from '../../../../../Assets/Logo.svg'
import styles from './Logo.module.css'

const logo = (props) => (
    <div className = {styles.Logo}>
        <img src = {Logo} alt = "Logo"/>
    </div>
)

export default logo