import { Divider } from '@material-ui/core'
import React from 'react'
import styles from './ViewDetails.module.css'
import RightArrow from '../../../../../Assets/RightArrow.svg'

const viewDetails = (props) => {

    return(
        <div className = {styles.ViewDetails}> 
        <a href = {props.studyURL} target="_blank">
            <div className = {styles.Container}>
                <p>View Details</p>
                <img src = {RightArrow}/>
            </div>
        </a>
        </div>
    )
    
    
}

export default viewDetails