import react, {Component} from 'react'
import AuthContainer from '../../../Components/Authentication/AuthContainer'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/authActions'

class SignUp extends Component {
    
    state = {
        signupContainerSetup: {
            authType : 'Register',
            title: 'Register',
            inputs:[
                {label: 'Your Email',
                type: 'email',
                key: 'email'},
                {label: 'Password',
                type: 'password',
                key: 'password'},
                {label: 'Confirm Password',
                type: 'password',
                key: 'confirmPassword'}
            ],
            buttonLabel: 'Join Now',
        }
    }

    onSubmitHandler = (email, password) => {
        const history = this.props.history
        this.props.onAuth(email, password, history)
    }

    render(){
        return(
            <AuthContainer onSubmitHandler = {this.onSubmitHandler} setup = {this.state.signupContainerSetup}/>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onAuth: (email, password, history) => dispatch(actions.auth(email, password, false, history))
    }
}

export default connect(null, mapDispatchToProps)(SignUp)