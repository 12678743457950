import React from 'react'
import styles from './Backdrop.module.css'

const backdrop = (props) => {

    let style = {
        marginTop: props.coverNavBar ? '0' : '5.1rem' ,
    }

    return(
        props.show ? <div className = {styles.Backdrop} style = {style} onClick = {props.clicked}></div> : null

    )
}

export default backdrop