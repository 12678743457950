import react, {Component} from 'react'
import AuthContainer from '../../../Components/Authentication/AuthContainer'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/authActions'


class SignUp extends Component {
    
    state = {
        signupContainerSetup: {
            authType : 'Login',
            title: 'Login',
            inputs:[
                {label: 'Your Email',
                type: 'email',
                key: 'email'},
                {label: 'Password',
                type: 'password',
                key: 'password'},
            ],
            buttonLabel: 'Login',
        }
    }

    onSubmitHandler = (email, password) => {
        const history = this.props.history
        this.props.onAuth(email,password, history)

    }

    render(){
        return(
            <AuthContainer onSubmitHandler = {this.onSubmitHandler} setup = {this.state.signupContainerSetup}/>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onAuth: (email,password, history) => dispatch(actions.auth(email, password, true, history))
    }
}

export default connect(null, mapDispatchToProps)(SignUp)