import react, {Component} from 'react'
import axios from '../../axios'
import Spinner from '../../Components/UI/Spinner/Spinner'
import SearchContainer from '../../Components/SearchContainer/SearchContainer'
import Screenings from '../../Components/Screenings/Screenings'
import styles from './Screening.module.css'

class Screening extends Component{
    state = {
        searchContainer: {
            badgeTitle: 'Find a Screening Center',
            subtitle: 'Get Screened for Prostate Cancer now',
            inputs: [
                {
                    key: 'location',
                    placeholder: 'Insert your Location',
                    type: null,
                }
            ]
        },

        searchInput: [
            {
                key: 'location',
                value: null
            }
        ], 

        loadingScreenings: false,
        screenings : []
    }

    inputChangeHandler = (event) =>{

        var updatedState = {...this.state}

        let inputToUpdate = updatedState.searchInput.find(input => {
           return input.key === event.target.name
        })


        const indexOfInputToUpdate = updatedState.searchInput.findIndex(input => {
            return input.key === inputToUpdate.key
        })


        inputToUpdate.value = event.target.value
        updatedState.searchInput[indexOfInputToUpdate] = inputToUpdate
        
        this.setState({
            ...this.updatedState
        })
    }

    

    locationChangeHandler = (newLocation) => {

        var updatedState = {...this.state}

        const selectedState = newLocation.description.split(",")[0] !== "New York" ? newLocation.description.split(",")[1] : newLocation.description.split(",")[0]
        const indexOfInputToUpdate = updatedState.searchInput.findIndex(input => {
            return input.key === "location"
        })

        updatedState.searchInput[indexOfInputToUpdate].value = selectedState

        this.setState({
            ...this.updatedState
        })

    }


    submitHandler = (event) => {
        event.preventDefault()
        let screenings = []
        this.setState({loadingScreenings: true})
        let location = this.state.searchInput[0].value ? this.state.searchInput[0].value : 'New York'
        axios.get('screening/get/bylocation', {params: {'location': location}}).then(response => {
            
            const data = response.data.data
            data.map(screening => {
                let newScreening = {}
                for (const [key,value] of Object.entries(screening)){
                    newScreening[key] = value
                }
                screenings.push(newScreening)
            })
            console.log(screenings)
            let updatedScreenings = {
                ...this.state.screenings
            }
            updatedScreenings = screenings
            this.setState({screenings: updatedScreenings, loadingScreenings: false})
        })
    }

    render(){

        let screenings = <Screenings screenings = {this.state.screenings}/>

        if(this.state.loadingScreenings){
            screenings = <Spinner/>
        }

        return(
            <div className = {styles.ScreeningPage}>
                <SearchContainer 
                setup = {this.state.searchContainer} 
                inputChangeHandler = {this.inputChangeHandler}
                submitHandler = {this.submitHandler}
                locationChangeHandler = {this.locationChangeHandler}
                loading = {this.state.loadingScreenings}/>
                {screenings}
            </div>
        )
    }
}

export default Screening