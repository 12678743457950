import React from 'react'
import styles from './SupportGroups.module.css'
import SupportGroup from './SupportGroup/SupportGroup'

const supportGroups = (props) => {
    return(
        <div className = {styles.SupportGroups}>
            {props.groups.map(supportGroup => (
                <SupportGroup key = {supportGroup.id} group = {supportGroup}/>
            ))}
        </div>
    )
}

export default supportGroups