import React from 'react'
import styles from './ToggleButton.scss'

const toggleButton = (props) => {
    const isActive = props.isActive
    return(
        <button className={`hamburger hamburger--elastic ${isActive ? 'is-active' : null} Button`} onClick = {props.clicked} type={"button"}>
            <span className={"hamburger-box"}>
                <span className={"hamburger-inner"}></span>
            </span>
        </button>
    )
}

export default toggleButton
