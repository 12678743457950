import React from 'react'
import styles from './SupportGroup.module.css'
import Image from '../../../ClinicalTrialsPage/Trials/Trial/Image/Image'
import ViewDetails from '../../../../Components/ClinicalTrialsPage/Trials/Trial/ViewDetails/ViewDetails'

const supportGroup = (props) => {
    const group = props.group

    return(
        <div className = {styles.SupportGroupContainer}>
            <div className = {styles.SupportGroup}>
                    <div className = {styles.Gap}></div>
                    <Image src = {group.image_url} className={styles.Image}/>
                    <h2 className = {styles.Title}>{group.name}</h2>
                    <p className = {styles.Summary}>This support group is hosted by {group.name}</p>
                    <div className = {styles.Fees}>
                        <label>Best For: </label>
                        <p>Prostate Cancer Patients and their families</p>
                    </div>
                    <div className = {styles.Perks}>
                        <label>Organizer: </label>
                        <p>{group.name}</p>
                    </div>
                    <div className = {styles.Address}>
                        <label>Location: </label>
                        <p>{group.location.address1}, {group.location.city}</p>
                    </div>
                    <div className = {styles.Timings}>
                        <label>Timings: </label>
                        <p>Every weekday from 9am to 6pm</p>
                    </div>
            </div>
            <ViewDetails studyURL = {group.url}></ViewDetails>
        </div>
    )
}

export default supportGroup