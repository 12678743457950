import * as actionTypes from './actionTypes'
import axios from 'axios'



export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token,id,email, history) => {
    localStorage.setItem('token', token)
    history.push('/me')
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        email: email,
        userId: id
    }
}

export const authFail = (error) => {

    let processedError = error
    if(typeof error !== 'string'){
        processedError = 'Invalid Credentials'
    }

    return{
        type: actionTypes.AUTH_FAIL,
        error: processedError
    }
}


export const authLogout = () => {
    return{
        type: actionTypes.AUTH_LOGOUT
    }
}

export const auth = (email, password, isLogin, history) => {
    return dispatch => {
        dispatch(authStart())
        let authData = {
            user: {
                email: email,
                password: password,
                type: 'Patient',
            }
        }
        let url = "https://prostate-ninja-develop.herokuapp.com/users/register"

        if(isLogin){
            url = "https://prostate-ninja-develop.herokuapp.com/users/login"
            authData = {
                user: {
                    email: email,
                    password: password,
                }
            }
        }
        console.log(authData)
        axios.post(url, authData).then(response => {
            dispatch(authSuccess(response.data.user.token, response.data.user._id, response.data.user.email, history))
        }).catch(error => {
            console.log('error:', error)
            dispatch(authFail(error))
        })
    }
}