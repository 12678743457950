import React from 'react'
import styles from './InputContainer.module.css'
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'



const inputContainer = (props) => {

    const emailInputChanged = (event) => {
        props.inputChangedHandler(event)
    }

    return(
        <div className = {styles.InputContainer}>
            <form>
                <TextField onChange = {emailInputChanged} key = "email" name = "email" variant = "outlined" className = {styles.Input} label = "Your Email"/>
                <FormControl variant = "outlined" className = {styles.Input}>
                    <InputLabel id="select-label">What is this About?</InputLabel>
                    <Select onChange = {props.inputChangedHandler} key = "messageType" name = "messageType" labelId="select-label" label = "What is this About?">
                        <MenuItem value = {0} >I would like to propose a new feature</MenuItem>
                        <MenuItem value = {1}>There is something wrong with the platform</MenuItem>
                        <MenuItem value = {2}>Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField onChange = {props.inputChangedHandler} 
                        key = "body"
                        variant = 'outlined' 
                        name = "body" 
                        className = {styles.Input} 
                        placeholder = 'Go ahead... We are listening' 
                        multiline
                        rows = {4}
                />
                <Button className = {styles.SearchButton} disabled = {props.loading} onClick = {props.submitHandler}>
                    {props.loading ? <CircularProgress size = {17} color = 'white'/> : 'Send'}
                </Button>
            </form>
        </div>
    )
}

export default inputContainer