import react from 'react'
import styles from './AuthForm.module.css'
import TextField from '@material-ui/core/TextField'
import {Link} from 'react-router-dom'
import ButtonSpinner from '../../Components/UI/ButtonSpinner/ButtonSpinner'
import {connect} from 'react-redux'
import ErrorBox from '../UI/ErrorBox/ErrorBox'

const AuthForm = (props) => {
    const setup = props.setup
    const switchTo = setup.authType === 'Register' ? '/login' : '/signup'

    return(
        <div className = {styles.AuthForm}>
            <h2 className = {styles.Title}>{setup.title}</h2>
            <form onSubmit = {props.submitHandler}>
                {setup.inputs.map(input => (
                    <TextField 
                        className = {styles.Input}
                        label = {input.label} 
                        variant = "outlined" 
                        type = {input.type}
                        key = {input.key}
                    ></TextField>
                ))}
                {props.error ? 
                    <ErrorBox message = {props.error}/> : null 
                }
                <ButtonSpinner loading = {props.authLoading}>
                    {setup.buttonLabel}
                </ButtonSpinner>
                <p className = {styles.Switch}>Or Switch to <Link to = {switchTo}>{setup.authType === 'Login' ? 'Register' : 'Login'}</Link></p>
            </form>
        </div>
    )
}

const mapStateToProps = state => {
    return{
        authLoading: state.auth.loading
    }
}

export default connect(mapStateToProps, null)(AuthForm)