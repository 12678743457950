import React from 'react'
import trial from './Trial/Trial'
import Trial from './Trial/Trial'
import styles from './Trials.module.css'

const trials = (props) => {
    return(
        <div className = {styles.Trials}>
            {props.trials.map(trial => (
                <Trial key = {trial.NCTid} trial = {trial}/>
            ))}
        </div>
    );
}

export default trials