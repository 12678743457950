import React from 'react'
import styles from './LandingSearchContainer.module.css'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import classes from './SearchContainer.module.css'
import SearchLocationInput from '../UI/SearchLocationInput/SearchLocationInput'
import BackgroundImage from '../../Assets/TrialsLandingPageBG.png'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles({
    root: {
      ' & .MuiOutlinedInput-root': {
          backgroundColor: 'white',
      }
    },
  });


const LandingSearchContainer = (props) => {

    const classes = useStyles()

    const setup = props.setup
    let buttonClasses = [styles.SearchButton]

    if(props.loading){
        buttonClasses.push(styles.Disabled)
    }

    const selectForm = <FormControl className={styles.HealthStatusContainer}>
                            <InputLabel shrink className = {styles.Label}>
                                Health Status
                            </InputLabel>
                            <Select 
                                onChange = {props.inputChangeHandler}
                                key = "healthStatus"
                                name = "healthStatus" 
                                labelId="demo-simple-select-placeholder-label-label"
                                id="demo-simple-select-placeholder-label"
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Prefer not to say</em>
                                </MenuItem>
                                <MenuItem value={1}> I am Healthy</MenuItem>
                                <MenuItem value={-1}>I am not Healthy</MenuItem>
                            </Select>
                            <FormHelperText className = {styles.HelperText} >Do you have Prostate Cancer?</FormHelperText>
                        </FormControl>

    return (
        <div className = {styles.LandingSearchContainer}>
            <h2 className = {styles.Badge}>{setup.badgeTitle}</h2>
            <div className = {styles.Title}>
                <h1>Clinical Trials</h1>
                <p className = {styles.TitleSubtitle}>Clinical trials offer hope for many people and an opportunity to help researchers find better treatments for others in the future.</p>
                <p className = {styles.TitleDescription}>
                    Clinical trials are research studies performed in people that are aimed at evaluating a new medical, surgical, or behavioral intervention.
                    A clinical trial is used to learn if a new treatment is more effective and/or has less harmful side effects than the standard treatment.
                    <br></br><br></br>Search below to browse thousand of Prostate Cancer Clinical Trials.
                </p>
            </div>

            <div className = {styles.Content}>
                <img className = {styles.BackgroundImage} src = {BackgroundImage} />
                <h1 className = {styles.Description}>{setup.subtitle}</h1>
                <form className = {styles.Form} onSubmit = {props.submitHandler}>
                    {setup.inputs.map(input => {
                        switch(input.key){
                            case "healthStatus":
                                return selectForm
                                break
                            case "location":
                                return <SearchLocationInput key = "location" 
                                    InputClass = {clsx(styles.Input, classes.root)} 
                                    locationChangeHandler = {props.locationChangeHandler}
                                    placeholder = {input.placeholder} 
                                    InputLabelProps = {{className: styles.InputLabel}}/>
                                break
                            default:
                                return <TextField 
                                    onChange = {props.inputChangeHandler} 
                                    key = {input.key} 
                                    name = {input.key} 
                                    InputLabelProps = {{className: styles.InputLabel }} 
                                    className = {clsx(classes.root, styles.Input)} 
                                    label = {input.placeholder} 
                                    variant = "outlined" 
                                    type = {input.type} 
                                    autoComplete = 'off'></TextField>
                        }
                    })
                    }
                    <button className = {buttonClasses.join(' ')}>
                        Search
                    </button>
                </form>
            </div>   
        </div>
    )
}

export default LandingSearchContainer