import React from 'react'
import styles from './Footer.module.css'
import Logo from '../Navigation/NavigationBar/AppNavTitle/Logo/Logo'
import MIT_logo from '../../Assets/MIT_logo.svg'
import makeHarvard_logo from '../../Assets/makeHarvard_logo.png'
import CapitalOne_logo from '../../Assets/capitalOne_logo.svg'
import MITSandbox_logo from '../../Assets/MITSandbox_logo.png'
import {NavLink, Link} from 'react-router-dom'

const footer = (props) => (
    <footer className = {styles.Footer}>
        <ul className = {styles.Info}>
            <li>
                <Logo/>
                <h1 className = {styles.Title}>ProstateNinja</h1>
            </li>
            <li>
                <ul className = {styles.Subtitle}> 
                    <li>Massachusetts Hall</li>
                    <li>Cambridge</li>
                    <li>MA, 0213</li>
                </ul>
            </li>
        </ul>
        <ul className = {styles.Links}>
            <Link className = {styles.Link} to = '/' exact >About Prostate Cancer</Link>
            <Link className = {styles.Link} to = '/clinicaltrials' exact >Clinical Trials</Link>
            <Link className = {styles.Link} to = '/support-groups' exact >Support Groups</Link>
            <Link className = {styles.Link} to = '/about' exact >About Us</Link>
            <Link className = {styles.Link} to = '/contact' exact >Careers</Link>
        </ul>

        <div className = {styles.Logos}>
            <a href = 'https://www.mit.edu/'>
                <img src = {MIT_logo}/>
            </a>
            <a href = 'https://www.capitalone.com/'>
                <img src = {CapitalOne_logo}/>
            </a>
            <a href = 'https://www.makeharvard.io/'>
                <img src = {makeHarvard_logo} className = {styles.NoPadding}/>
            </a>
            <a href = 'http://sandbox.mit.edu/'> 
                <img src = {MITSandbox_logo} style = {{width: '200px'}}className = {styles.NoPadding}/>
            </a>
        </div>
    </footer>
)

export default footer