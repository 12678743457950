import React from 'react'
import styles from './Toolbar.module.css'
import ToolbarItems from './ToolbarItems/ToolbarItems'

const toolbar = (props) => (
    <div className = {styles.Toolbar}>
        <nav>
            <ToolbarItems/>
        </nav>
    </div>
    
)

export default toolbar