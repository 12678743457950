import logo from './logo.svg';
import './App.css';
import Layout from './Components/Layout/Layout'

import ProstateCancerPage from './Containers/ProstateCancer/ProstateCancerPage'
import ClinicalTrialsPage from './Containers/ClinicalTrials/ClinicalTrialsPage'
import SupportGroupsPage from './Containers/SupportGroups/SupportGroupsPage'
import AboutPage from './Containers/About/AboutPage'
import ContactPage from './Containers/Contact/ContactPage'
import UnderConstruction from './Components/UnderConstruction/UnderContruction'
import Signup from './Containers/Authentication/Signup/Signup'
import Login from './Containers/Authentication/Login/Login'
import PatientConsole from './Containers/Consoles/PatientConsole/PatientConsole'
import Screening from './Containers/Screening/Screening'

import {Route, NavLink, Switch,BrowserRouter, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

function App(props) {
  return (
    <div className = "App">
      <BrowserRouter>
        <Layout>
            <Switch>
              <Route path = "/" exact component = {ProstateCancerPage}/>
              <Route path = "/clinicaltrials" component = {ClinicalTrialsPage}/>
              <Route path = "/support-groups"  component = {SupportGroupsPage}/>
              <Route path = "/about" component = {AboutPage}/>
              <Route path = "/contact" component = {ContactPage}/>
              
              <Route path = "/screening" component = {Screening}/>
              {props.isAuthenticated ?
                  <Route path = "/me" component = {PatientConsole} ></Route> : null
              }
            </Switch>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => {
  return{
    isAuthenticated : state.auth.token ? true : false
  }
}

export default connect(mapStateToProps, null)(App);
