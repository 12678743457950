import React, {Component} from 'react'
import styles from './ProstateCancerPage.module.css'
import Boxes from '../../Components/ProstateCancerPage/Boxes/Boxes'
import Box from '../../Components/ProstateCancerPage/Boxes/Box/Box'

class ProstateCancerPage extends Component {
    render(){
        return(
            <div className = {styles.ProstateCancerPage}>
                <div className = {styles.Banner}>
                    <h1 className = {styles.Title}>What is Prostate Cancer ?</h1>
                    <div className = {styles.Info}>
                        <p>Prostate cancer is the most common non-skin cancer in America.</p>
                        <p>In the United States, 1 out of every 9 men will be diagnosed with prostate cancer in their lifetime.</p>
                        <p>Learn more about the symptoms to watch out for, screening recommendations, causes, and survival rates below.</p>
                    </div>
                </div>
                <Boxes/>
            </div>
        )
    }
}

export default ProstateCancerPage