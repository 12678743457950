import react, {Component} from 'react'
import styles from './PatientConsole.module.css'
import Modal from '../../../Components/UI/Modal/Modal'
import * as actions from '../../../store/actions/authActions'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

class PatientConsole extends Component {

    state = {
        wantsToLogout : false
    }

    logOutStartedHandler = () => {
        this.setState({wantsToLogout : true})
    }

    logOutCancelledHandler = () => {
        this.setState({wantsToLogout: false})
    }

    logOutHandler = () => {
        this.props.history.push('/login')
        console.log('called')
        this.props.onAuthLogout()
        this.setState({wantsToLogout: false})
    }

    render(){
        return(
        <div className = {styles.PatientConsole}>
            <Modal show = {this.state.wantsToLogout} modalClosed = {this.logOutCancelledHandler}>
                Are you sure you want to log out?
                <div className = {styles.modalButtonForm}>
                    <button onClick = {this.logOutHandler}>Yes</button>
                    <button onClick = {this.logOutCancelledHandler}>No</button>
                </div>
            </Modal>
            <button className = {styles.LogoutButton} onClick = {this.logOutStartedHandler}>Log Out</button>
            <h2 className = {styles.userStatus} >Hey, you are a patient</h2>
            <button className = {styles.StartChattingButton}>Get in touch with a doctor now</button>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        isAuthenticated: state.auth.token ? true: false
    } 
}

const mapDispachToProps = dispatch => {
    return{
        onAuthLogout: () => dispatch(actions.authLogout())
    }
}

export default connect(mapStateToProps,mapDispachToProps)(PatientConsole)