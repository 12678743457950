import react from 'react'
import styles from './AuthContainer.module.css'
import AuthForm from './AuthForm'
import AuthIllustration from '../../Assets/AuthIllustration.svg'
import {connect} from 'react-redux'
import * as actions from '../../store/actions/authActions'

const AuthContainer = (props) => {
    
    const doPasswordsMatch = (psw1, psw2) => {
        return psw1 === psw2 ? true : false
    }
    
    const checkValidity = (event) => {
        let isValid = true
        let reason = null
        for(const input of event.target){
            const value = input.value
            switch(input.type){
                case 'email':
                    const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                    isValid = emailPattern.test(value) && isValid
                    reason = !isValid ? 'Oops, looks like the Email is not valid' : null
                    if(!isValid){
                        return [isValid, reason]
                    }
                    break

                case 'password':
                    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
                    isValid = passwordPattern.test(value) && isValid
                    reason = !isValid ? 'The password must be minimum 8 characters long and contain one uppercase letter, one lowercase and a number' : null
                    if(!isValid){
                        return [isValid, reason]
                    }
                    break
            }
        }
        if(props.setup.authType === 'Register'){
            if(doPasswordsMatch(event.target[2].value, event.target[4].value)){
                isValid = true 
                reason = null
            }else{
                isValid = false
                reason = 'Passwords do not match'
            }
        }
    
        return [isValid,reason]
    }

    async function hashMessage(psw) {
        const msgUint8 = new TextEncoder().encode(psw);                               // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
      }
      

    const formSubmitHandler = async (event) => {
        event.preventDefault()
        const [isValid, reason] = checkValidity(event)

        if(isValid && reason === null){
            const hashedPassword = await hashMessage(event.target[2].value)
            props.onSubmitHandler(event.target[0].value, hashedPassword)
        }else{
            props.onAuthFailed(reason)
        }

        
    }

    return(
        <div className = {styles.AuthContainer}>
            <AuthForm error = {props.error} submitHandler = {formSubmitHandler} setup = {props.setup}></AuthForm>
            <img className = {styles.Image} src = {AuthIllustration}/>
        </div>
    )
}

const mapStateToProps = state => {
    return{
        error: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onAuthFailed: (error) => dispatch(actions.authFail(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)