import React, {Component} from 'react'
import styles from './ContactPage.module.css'
import InputContainer from '../../Components/ContactPage/InputContainer/InputContainer'
import axios from '../../axios'
import withError from '../../hoc/withErrorHandler'

class ContactPage extends Component{

    state = {
        email: '',
        messageType: '', //Can be new feature, other, problem
        body: '',
        tryingToSend: false,
    }

    inputChangedHandler = (event) => {
        const updatedState = {...this.state}
        if(event.target.name == 'messageType'){
            switch (event.target.value){
                case 0:
                    updatedState['messageType'] = "NEW-FEATURE-PROPOSAL"
                    break
                case 1:
                    updatedState['messageType'] = "PLATFORM-ERROR"
                    break
                case 2:
                    updatedState['messageType'] = "OTHER"
                    break
            }
        }else{
            updatedState[event.target.name] = event.target.value
        }
        this.setState({...updatedState})
    }

    submitHandler = (event) => {
        event.preventDefault()
        this.setState({tryingToSend: true})
        axios.post('/contact', {
            "email": this.state.email,
            "subject": this.state.messageType,
            "messageType": this.state.messageType,
            "body": this.state.body
        }).then((response) => {
            console.log(response)
            this.setState({tryingToSend: false})
        }).catch((error) => {
            console.log(error)
            this.setState({tryingToSend: false})
        })

    }

    render(){
        return(
            <div className = {styles.ContactPage}>
                <h1 className = {styles.Title}>Let's Connect</h1>
                <InputContainer submitHandler = {this.submitHandler} inputChangedHandler = {this.inputChangedHandler} loading = {this.state.tryingToSend}/>
            </div>
        )
    }
}

export default withError(ContactPage, axios)