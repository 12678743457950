import react from 'react'
import cslx from 'clsx'
import styles from './ButtonSpinner.module.css'

const buttonSpinner = (props) => (
    <div className = {styles.ButtonSpinner}>
        <button type="submit">
            {props.children}
            {props.loading ? <span className={cslx(styles.spinner_border, styles.spinner_border_sm) } role="status" aria-hidden="true"></span> : null}
        </button>
    </div>
    
)

export default buttonSpinner