import React, {Component} from 'react'
import styles from './SupportGroupsPage.module.css'
import SearchContainer from '../../Components/SearchContainer/SearchContainer'
import SupportGroups from '../../Components/SupportGroupsPage/SupportGroups/SupportGroups'
import axios from '../../axios'
import Spinner from '../../Components/UI/Spinner/Spinner'

class SupportGroupsPage extends Component{

    state = {
        searchContainer: {
            badgeTitle: 'Support Groups',
            subtitle: 'Find a Support Group near You',
            inputs: [
                {
                    key: 'location',
                    placeholder: 'Insert your Location',
                    type: null,
                }
            ]
        },

        searchInput: [
            {
                key: 'location',
                value: null
            }
        ], 

        loadingSupportGroups: false,
        groups : []
    }

    inputChangeHandler = (event) =>{

        var updatedState = {...this.state}

        let inputToUpdate = updatedState.searchInput.find(input => {
           return input.key === event.target.name
        })


        const indexOfInputToUpdate = updatedState.searchInput.findIndex(input => {
            return input.key === inputToUpdate.key
        })


        inputToUpdate.value = event.target.value
        updatedState.searchInput[indexOfInputToUpdate] = inputToUpdate
        
        this.setState({
            ...this.updatedState
        })
    }


    submitHandler = (event) => {
        event.preventDefault()
        let groups = []
        this.setState({loadingSupportGroups: true})
        let location = this.state.searchInput[0].value ? this.state.searchInput[0].value : 'New York'
        axios.get('supportGroup/get/bylocation', {params: {'location': location}}).then(response => {
            
            const data = response.data.data
            data.map(group => {
                let newGroup = {}
                for (const [key,value] of Object.entries(group)){
                    newGroup[key] = value
                }
                groups.push(newGroup)
            })
            console.log(groups)
            let updatedGroups = {
                ...this.state.groups
            }
            updatedGroups = groups
            this.setState({groups: groups, loadingSupportGroups: false})
        })
    }

    render(){

        let groups = <SupportGroups groups = {this.state.groups}/>

        if(this.state.loadingSupportGroups){
            groups = <Spinner/>
        }

        return(
            <div className = {styles.SupportGroupsPage}>
                <SearchContainer 
                setup = {this.state.searchContainer} 
                inputChangeHandler = {this.inputChangeHandler}
                submitHandler = {this.submitHandler}
                locationChangeHandler = {this.locationChangeHandler}
                loading = {this.state.loadingSupportGroups}/>
                {groups}
            </div>
        )
    }
}

export default SupportGroupsPage