import React, {Component} from 'react'
import styles from './AboutPage.module.css'
import Luca from '../../Assets/Luca.jpg'
import Malhaar from '../../Assets/Malhaar.jpg'
import Avanish from '../../Assets/AvanishGupta.png'
import Avinash from '../../Assets/Avinash.png'
import Quincy from '../../Assets/Quincy.jpg'
import Jimmy from '../../Assets/jimmy.jpg'
import McNeil from '../../Assets/mcneil.jpg'
import Sidhu from '../../Assets/sidhu.jpg'
import TeamIllustration from '../../Assets/TeamIllustration.svg'

class AboutPage extends Component{
    render(){
        return(
            <div className = {styles.AboutPage}>
                <div className = {styles.Info}>
                    <img src = {TeamIllustration}></img>
                    <h1>About Us</h1>
                    <p> We are an International Team of students with backgrounds ranging from UI/UX, mobile development to healthcare. <br/>
                        ProstateNinja is an MIT-backed and Award Winning platform, which seeks to improve Prostate Cancer Information and Outcomes.
                    </p>
                </div>
                <div className = {styles.Team}>
                    <div className = {styles.Member}>
                        <img src = {Malhaar} style = {{objectPosition: '75% 90%'}}/>
                        <h3>Malhaar Agrawal</h3>
                        <p>Co-founder and President</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Luca}/>
                        <h3>Luca Sfragara</h3>
                        <p>Co-founder and Vice President</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Avinash}/>
                        <h3>Avinash Upadhyaya K R</h3>
                        <p>Co-founder and Software Engineer</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Avanish}/>
                        <h3>Avanish Gupta</h3>
                        <p>Co-Founder and Software Engineer</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Quincy}/>
                        <h3>Quincy van den Berg</h3>
                        <p>Software Engineer</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Jimmy}/>
                        <h3>Jimmy Toh</h3>
                        <p>Business Development</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {Sidhu}/>
                        <h3>Dr. Gurinder S. Sidhu, MD</h3>
                        <p>Scientific Advisor</p>
                    </div>
                    <div className = {styles.Member}>
                        <img src = {McNeil} style = {{objectPosition: '10% 10%'}}/>
                        <h3>Brian K. McNeil, MD</h3>
                        <p>Scientific Advisor</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutPage