import React from 'react'
import styles from './SearchContainer.module.css'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import classes from './SearchContainer.module.css'
import SearchLocationInput from '../UI/SearchLocationInput/SearchLocationInput'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SearchContainer = (props) => {

    const setup = props.setup
    let buttonClasses = [styles.SearchButton]

    if(props.loading){
        buttonClasses.push(styles.Disabled)
    }

    const selectForm = <FormControl className={styles.HealthStatusContainer}>
                            <InputLabel shrink className = {styles.Label}>
                                Health Status
                            </InputLabel>
                            <Select 
                                onChange = {props.inputChangeHandler}
                                key = "healthStatus"
                                name = "healthStatus" 
                                labelId="demo-simple-select-placeholder-label-label"
                                id="demo-simple-select-placeholder-label"
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Prefer not to say</em>
                                </MenuItem>
                                <MenuItem value={1}> I am Healthy</MenuItem>
                                <MenuItem value={-1}>I am not Healthy</MenuItem>
                            </Select>
                            <FormHelperText className = {styles.HelperText} >Do you have Prostate Cancer?</FormHelperText>
                        </FormControl>

    return (
        <div className = {styles.SearchContainer}>
            <h2 className = {styles.Badge}>{setup.badgeTitle}</h2>
            <p>{setup.subtitle}</p>
            <form className = {styles.Form} onSubmit = {props.submitHandler}>
                {setup.inputs.map(input => {
                    switch(input.key){

                        case "healthStatus":
                            return selectForm
                            break

                        case "location":
                            return <SearchLocationInput key = "location" InputClass = {styles.Input} locationChangeHandler = {props.locationChangeHandler} placeholder = {input.placeholder} InputLabelProps = {{className: styles.InputLabel}}/>
                            break
                        default:
                            return <TextField onChange = {props.inputChangeHandler} key = {input.key} name = {input.key} InputLabelProps = {{className: styles.InputLabel }} className = {`${styles.Input}`} label = {input.placeholder} variant = "outlined" type = {input.type} autoComplete = 'off'></TextField>
                    }
                })
                }
                <button className = {buttonClasses.join(' ')}>
                    Search
                </button>
            </form>
            
        </div>
    )
}

export default SearchContainer