import React from 'react'
import styles from './Screening.module.css'
import Image from '../../ClinicalTrialsPage/Trials/Trial/Image/Image'
import ViewDetails from '../../ClinicalTrialsPage/Trials/Trial/ViewDetails/ViewDetails'

const supportGroup = (props) => {
    const screening = props.screening

    return(
        <div className = {styles.ScreeningContainer}>
            <div className = {styles.Screening}>
                    <div className = {styles.Gap}></div>
                    <Image src = {screening.image_url} className={styles.Image}/>
                    <h2 className = {styles.Title}>{screening.name}</h2>
                    <p className = {styles.Summary}> Screening facility: {screening.name}</p>
                    <div className = {styles.Fees}>
                        <label>Fee: </label>
                        <p>Not available</p>
                    </div>
                    <div className = {styles.Perks}>
                        <label>Organizer: </label>
                        <p>{screening.name}</p>
                    </div>
                    <div className = {styles.Address}>
                        <label>Location: </label>
                        <p>{screening.location.address1}, {screening.location.city}</p>
                    </div>
                    <div className = {styles.Timings}>
                        <label>Get in touch: </label>
                        <p>{screening.phone}</p>
                    </div>
            </div>
            
        </div>
    )
}

export default supportGroup