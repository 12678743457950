import react from 'react'
import styles from './LandingPage.module.css'
import LandingSearchContainer from '../../SearchContainer/LandingSearchContainer'

const landingPage = (props) => (
    <LandingSearchContainer
        submitHandler = {props.submitHandler} 
        inputChangeHandler = {props.inputChangeHandler} 
        locationChangeHandler = {props.locationChangeHandler} 
        loading = {props.loading} 
        setup = {props.setup}
    
    ></LandingSearchContainer>
)

export default landingPage