import React from 'react'
import styles from './Box.module.css'
import BoxImage from './BoxImage/BoxImage'


const box = (props) => (
    <a href = {props.link} target = '_blank'>
        <div className = {styles.Box} style = {{backgroundImage: `url(${props.img})`}}>
            <div className = {styles.Banner}>
                <p>{props.title}</p>
            </div>
        </div>
    </a>
    
)

export default box