import React from 'react'
import styles from './Screenings.module.css'
import Screening from './Screening/Screening'

const screenings = (props) => {
    return(
        <div className = {styles.Screenings}>
            {props.screenings.map(screening => (
                <Screening key = {screening.id} screening = {screening}/>
            ))}
        </div>
    )
}

export default screenings