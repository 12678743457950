import React from 'react'
import Logo from './Logo/Logo'
import styles from './AppNavTitle.module.css'

const appNavTitle = (props) => (
    <div className = {styles.NavigationBarTitle}>
            <Logo/>
            <h1>Prostate<span>Ninja</span></h1>
    </div>
)

export default appNavTitle